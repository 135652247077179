module.exports = {
  SiteTitle: "Robert Hardi",
  Sitelogo: "#",
  SiteLogoText: "RobertHardi",
  SiteAuthor: "Robert Hardi",
  SiteDescription: "GASTROENTEROLOGIST",
  defaultDescription: "GASTROENTEROLOGIST",
  SiteSocialLinks: {
    facebook: "https://www.facebook.com/robert.hardi.5",
    linkedin: "https://www.linkedin.com/in/robert-hardi-733b8ba/",
  },
  SiteAddress: {
    city: "",
    region: "",
    country: "",
    zipCode: "",
  },
  SiteContact: {
    email: "drroberthardi@gmail.com",
    phone: "+13106564545",
  },
  SiteCopyright: "2021",
}
